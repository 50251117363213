<template>
  <v-card>
    <v-card-title v-if="this.$route.meta.type === 'schools_gov'">
      {{ $t('schools2') }} {{ this.governorate.name }}
    </v-card-title>
    <v-card-title v-else-if="this.$route.meta.type === 'schools_dir'">
      {{ $t('schools2') }} {{ this.directorate.name }}
    </v-card-title>
    <v-card-title v-else>
      {{ $t('schools') }}
    </v-card-title>
    <v-spacer></v-spacer>

    <v-data-table
      class="dd"
      :headers="headers"
      :items="schools"
      :options.sync="options"
      :server-items-length="schoolsTotal"
      :loading="loading"
      :disable-filtering="true"
      :footer-props="{
        showFirstLastPage: true,
        itemsPerPageOptions:[10, 20, 50, 100, 500],
        itemsPerPageText: $t('dataTable.itemsPerPageText'),
        pageText: $t('dataTable.pageText'),
        itemsPerPageAllText: $t('dataTable.itemsPerPageAllText'),
      }"
      :hide-default-header="!$vuetify.breakpoint.mobile"
      @update:page="fetch"
      @update:items-per-page="fetch"
    >
      <template v-if="!$vuetify.breakpoint.mobile" v-slot:header="{ props }">
        <datatable-header :props="props" :options.sync="options" :translation-key="translationKey"
                          @update:filters="fetch"></datatable-header>
      </template>
      <template v-slot:header.actions="{ header }">
        {{ header.text }}
      </template>

      <template v-slot:item.new_numbers="{ item }">


        <v-chip
          color='#00bcd4'
          label
          class='mx-1'
          small
        >
          {{item.s_new_numbers}}
        </v-chip>

        <v-chip
          color='#00bcd4'
          label
          class='mx-1'
          small
        >
          {{item.t_new_numbers}}
        </v-chip>

        <v-chip
          color='#00bcd4'
          label
          class='mx-1'
          small
        >
          {{item.d_new_numbers}}
        </v-chip>


      </template>
      <template v-slot:item.locked_numbers="{ item }">

        <v-chip
          color='error'
          label
          class='mx-1'
          small
        >
          {{item.s_locked_numbers}}
        </v-chip>
        <v-chip
          color='error'
          label
          class='mx-1'
          small
        >
          {{item.t_locked_numbers}}
        </v-chip>
        <v-chip
          color='error'
          label
          class='mx-1'
          small
        >
          {{item.d_locked_numbers}}
        </v-chip>





      </template>
      <template v-slot:item.active_numbers="{ item }">
        <v-chip
          color='success'
          label
          class='mx-1'
          small
        >
          {{item.s_active_numbers}}
        </v-chip>
        <v-chip
          color='success'
          label
          class='mx-1'
          small
        >
          {{item.t_active_numbers}}
        </v-chip>
        <v-chip
          color='success'
          label
          class='mx-1'
          small
        >
          {{item.d_active_numbers}}
        </v-chip>




      </template>
      <template v-slot:item.sent_numbers="{ item }">
        <v-chip
          color='primary'
          label
          class='mx-1'
          small
        >
          {{item.s_sent_numbers}}
        </v-chip>
        <v-chip
          color='primary'
          label
          class='mx-1'
          small
        >
          {{item.t_sent_numbers}}
        </v-chip>
        <v-chip
          color='primary'
          label
          class='mx-1'
          small
        >
          {{item.d_sent_numbers}}
        </v-chip>

      </template>



      <template v-slot:item.governorate="{ item }">

          {{ item.governorate }}
          <br>
          {{ item.directorate }}
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn
          class="mx-1"
          color="info"
          x-small
          @click="showStudents( item.id)"
        >
          <v-icon
            dark
          >
            mdi-account-group
          </v-icon>
        </v-btn>
        <v-btn
          class="mx-1"
          color="info"
          x-small
          @click="showTeachers( item.id)"
        >
          <v-icon
            dark
          >
            mdi-account-school
          </v-icon>
        </v-btn>
        <v-btn
          class="mx-1"
          color="info"
          x-small
          @click="showDrivers( item.id)"
        >
          <v-icon
            dark
          >
            mdi-car
          </v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import axios from '@axios'
import DatatableHeader from '@/components/dataTables/DatatableHeader.vue'

import {ObjectToQuery} from '@/plugins/helper';
import {getFilters, getOptions} from '@/components/dataTables/helper'
import router from '@/router'

export default {
  name: 'Schools',
  components: {DatatableHeader},
  data: () => ({
    translationKey: 'pages.schools',
    filters: false,
    schools: [],
    directorate: '',
    governorate: '',
    schoolsTotal: 0,
    loading: true,
    options: {},
    tempOptions: '',
    search: '',
    dialogData: {
      dialog: false,
      type: 'create',
      id: null,
    },
    headers: [
      {
        text: 'name',
        align: 'center',
        sortable: false,
        value: 'name',
        sort: true,
        filter: {
          menu: false,
          type: 'text',
          methods: ['contains', 'startWith', 'endWith', 'equal', 'notEqual'],
        },
      },


      {
        text: 'governorate',
        align: 'center',
        sortable: false,
        value: 'governorate',
        sort: true,
        filter: {
          menu: false,
          type: 'text',
          methods: ['contains', 'startWith', 'endWith', 'equal', 'notEqual'],
        },
      },

      {
        text: 'domain',
        align: 'center',
        sortable: false,
        value: 'domain',
        sort: true,
        filter: {
          menu: false,
          type: 'text',
          methods: ['contains', 'startWith', 'endWith', 'equal', 'notEqual'],
        },
      },
      {
        text: 'new_numbers',
        align: 'center',
        sortable: false,
        value: 'new_numbers',
        sort: true,
        filter: {
          menu: false,
          type: 'number-search',
          methods: ['greater', 'equal', 'less'],
          my_options: ['students', 'teachers', 'drivers'],

        },
      },
      {
        text: 'active_numbers',
        align: 'center',
        sortable: false,
        value: 'active_numbers',
        sort: true,
        filter: {
          menu: false,
          type: 'number-search',
          methods: ['greater', 'equal', 'less'],
          my_options: ['students', 'teachers', 'drivers'],
        },
      },
      {
        text: 'locked_numbers',
        align: 'center',
        sortable: false,
        value: 'locked_numbers',
        sort: true,
        filter: {
          menu: false,
          type: 'number-search',
          methods: ['greater', 'equal', 'less'],
          my_options: ['students', 'teachers', 'drivers'],
        },
      },
      {
        text: 'sent_numbers',
        align: 'center',
        sortable: false,
        value: 'sent_numbers',
        sort: true,
        filter: {
          menu: false,
          type: 'number-search',
          methods: ['greater', 'equal', 'less'],
          my_options: ['students', 'teachers', 'drivers'],
        },
      },

      {
        text: 'actions',
        value: 'actions',
        align: 'center',
      },
    ],
  }),
  computed: {
    type() {
      return this.$route.meta.type;
    },
  },
  async mounted() {
    this.options = getOptions(this.$route, this.options);
    await this.fetch();
    if (this.$route.params.id) {
      if (this.$route.meta.type === 'schools_gov') {
        const response = await axios.get(`api/governorates/${this.$route.params.id}`);
        this.governorate = response.data.data;
      } else {
        const response = await axios.get(`api/directorates/${this.$route.params.id}`);
        this.directorate = response.data.data;
      }
    }
  },
  methods: {
    async fetch() {
      if (JSON.stringify(this.options) !== this.tempOptions) {
        this.tempOptions = JSON.stringify(this.options);
        await this.$router.replace({query: this.options}).catch(() => {
        });
      }
      window.scrollTo(0, 0);
      localStorage.setItem(`itemsPerPage_${this.$route.name}`, this.options.itemsPerPage);
      this.loading = true;
      const filters = getFilters(this.headers);
      const conditions = {
        ...this.options,
        filters,
      }

      // eslint-disable-next-line default-case
      switch (this.type) {
        case 'schools_gov':
          conditions.governorate_id = this.$route.params.id;
          break
        case 'schools_dir':
          conditions.directorate_id = this.$route.params.id;
          break
      }
      const response = await axios.get(`/schools?${ObjectToQuery(conditions)}`);
      this.schools = response.data.data.items
      this.schoolsTotal = response.data.data.meta.total
      this.loading = false
    },

    async showTeachers(id) {
      await router.push(`/teachers/${id}`);
    },
    async showStudents(id) {
      await router.push(`/students/${id}`);
    },
    async showDrivers(id) {
      await router.push(`/drivers/${id}`);
    },

    $(key, parameters = null) {
      return this.$t(`${this.translationKey}.${key}`, parameters);
    },
  },

}
</script>
